/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}

:root {
  --dineup-base-color: black;
}

:root {
  --gray-900: #0c0e0e;
  --gray-800: #23292a;
  --gray-700: #394546;
  --gray-600: #516062;
  --gray-500: #677b7e;
  --gray-400: #c1cbcc;
  --gray-300: #d0d7d8;
  --gray-200: #dfe4e5;
  --gray-100: #edf0f1;
  --gray-050: #fcfdfd;

  --primary-900: #144a52;
  --primary-800: #1b636d;
  --primary-700: #227c88;
  --primary-600: #2995a3;
  --primary-500: #30aebe;
  --primary-400: #41bfcf;
  --primary-300: #5cc8d6;
  --primary-200: #77d1dd;
  --primary-100: #92dae4;
  --primary-050: #ade3eb;

  --red: #cc3333;
}

html {
  background: var(--gray-100);
}
